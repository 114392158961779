import { t } from '@zupr/i18n'
import { useContext } from 'react'

import Badge, { LoadingBadge } from '../../../../shared/components/badge'

import ShoppingListContext from '../../../../context/shopping-list'

import { useIsMobile } from '../../../../context/ux'
import { useShoppingListsStatus } from '../../../features/shopping-list/hooks/lists'

import ChatIcon from '../../../../../svg/chat.svg'
import ShoppingListIcon from '../../../../../svg/shopping-cart-outlined.svg'

const ShoppingListButton = () => {
    const isMobile = useIsMobile()
    const { onToggleSidebar, numberOfProducts } =
        useContext(ShoppingListContext)

    const { isRequesting, isAnswered, isSeen } = useShoppingListsStatus()

    return (
        <button
            className="inline toggle-shopping-list"
            onClick={onToggleSidebar}
            aria-label={t('Shopping list')}
        >
            <span>
                <ShoppingListIcon />
                <Badge small warning>
                    {numberOfProducts}
                </Badge>
                {isRequesting && <LoadingBadge info />}
                {isAnswered && !isSeen && (
                    <Badge small info bounce>
                        <ChatIcon />
                    </Badge>
                )}
            </span>
            {!isMobile && <span>{t('Shopping list')}</span>}
        </button>
    )
}

export default ShoppingListButton
