import Link from 'next/link'
import { useContext, useEffect, useState } from 'react'

import { locationUrl } from '@zupr/utils/url'
import OmniSearch from '../search/location'
import SiteMessages from '../site-messages'

import { useIsMobile } from '../../../context/ux'
import ErrorBoundary from '../../../shared/error/boundary'

import DomainContext from '../../../context/domain'
import WhenOpen from '../../../shared/location/hours/when-open'
import Buttons from './components/buttons'
import LocationMobileMenu from './components/location-menu'

import Clock from '../../../../svg/clock.svg'

import '../../../../scss/react/frontoffice/site-header.scss'
import '../../../../scss/react/frontoffice/site-menu.scss'
import '../../../../scss/react/frontoffice/site-top-location.scss'

const SiteTop = () => {
    const isMobile = useIsMobile()
    const { location } = useContext(DomainContext)

    const [mobileMenu, setMobileMenu] = useState(false)

    useEffect(() => {
        if (!isMobile && mobileMenu) setMobileMenu(false)
    }, [isMobile, mobileMenu])

    return (
        <ErrorBoundary>
            <div className="site-top">
                <div className="site-header site-header-location">
                    <div className="inner">
                        <div className="branding location-when-open">
                            {isMobile && (
                                <LocationMobileMenu location={location} />
                            )}
                            {!isMobile && (
                                <Link href={locationUrl(location, 'home')}>
                                    <a>
                                        <Clock />
                                        <WhenOpen {...location} />
                                    </a>
                                </Link>
                            )}
                        </div>

                        {!isMobile && (
                            <div className="search">
                                <OmniSearch location={location} />
                            </div>
                        )}

                        <Buttons />
                    </div>
                </div>
                <SiteMessages />
            </div>
        </ErrorBoundary>
    )
}

export default SiteTop
