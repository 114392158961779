import { useRequest } from '@zupr/hooks/request-next'
import { t } from '@zupr/i18n'
import { Category } from '@zupr/types/fo'
import classNames from 'classnames'
import Link from 'next/link'
import React, { useContext, useEffect, useMemo, useState } from 'react'

import NavLink from '../../../shared/router/navlink'

import AreaContext from '../../../context/domain'
import { useIsMobile } from '../../../context/ux'

import Chevron from '../../../../svg/chevron.svg'

import '../../../../scss/react/frontoffice/categories.scss'
import '../../../../scss/react/frontoffice/new-menu.scss'

interface Props {
    parent: Category
    categories: Category[]
    onClick?: () => void
}

export const SubMenu = ({ parent, onClick, categories }: Props) => {
    const itemsWithoutChildren = useMemo(
        () =>
            categories
                .filter(({ depth }) => depth === 2)
                .filter(
                    ({ path }) =>
                        !categories.some(
                            (child) =>
                                child.depth === 3 && child.path.startsWith(path)
                        )
                ) || [],
        [categories]
    )

    const itemsWithChildren = useMemo(
        () =>
            categories
                .filter(({ depth }) => depth === 2)
                .filter(({ path }) =>
                    categories.some(
                        (child) =>
                            child.depth === 3 && child.path.startsWith(path)
                    )
                ) || [],
        [categories]
    )

    return (
        <div className="deeper-menu">
            {!itemsWithoutChildren.length &&
                !itemsWithChildren.length &&
                !!parent && (
                    <ul>
                        <li>
                            <NavLink
                                exact
                                onClick={onClick}
                                href={`/producten/${parent.slug}`}
                            >
                                <strong>{parent.name}</strong>
                            </NavLink>
                        </li>
                    </ul>
                )}

            {!!itemsWithoutChildren.length && !!parent && (
                <ul>
                    <li>
                        <NavLink
                            exact
                            onClick={onClick}
                            href={`/producten/${parent.slug}`}
                        >
                            <strong>{parent.name}</strong>{' '}
                        </NavLink>
                        {!!itemsWithoutChildren.length && (
                            <ul>
                                {itemsWithoutChildren.map(
                                    ({ slug, name, productCount }) => (
                                        <li>
                                            <NavLink
                                                onClick={onClick}
                                                href={`/producten/${slug}`}
                                            >
                                                {name}
                                            </NavLink>
                                        </li>
                                    )
                                )}
                            </ul>
                        )}
                    </li>
                </ul>
            )}
            {itemsWithChildren.map(({ slug, name, productCount, path }) => (
                <ul>
                    <li>
                        <NavLink
                            exact
                            onClick={onClick}
                            href={`/producten/${slug}`}
                        >
                            <strong>{name}</strong>
                        </NavLink>
                        <ul>
                            {categories
                                .filter(
                                    (child) =>
                                        child.depth === 3 &&
                                        child.path.startsWith(path)
                                )
                                .map((child) => (
                                    <li>
                                        <NavLink
                                            onClick={onClick}
                                            href={`/producten/${child.slug}`}
                                        >
                                            {child.name}
                                        </NavLink>
                                    </li>
                                ))}
                        </ul>
                    </li>
                </ul>
            ))}
        </div>
    )
}

SubMenu.defaultProps = {
    categories: [],
}

export const MobileCategories = ({ onClick, onCloseCategories }) => {
    const [activePath, setActivePath] = useState([])
    const { shoppingAreaSlug } = useContext(AreaContext)

    const active = activePath.length > 0 && activePath[activePath.length - 1]
    const parent = activePath.length > 1 && activePath[activePath.length - 2]

    const [categories]: [Category[]] = useRequest({
        url: 'categories',
        variables: {
            shoppingAreaSlug,
            layers: 3,
        },
    })

    const filteredCategories = useMemo(() => {
        if (!categories) return
        if (!active && categories)
            return categories.filter(({ depth }) => depth === 1)
        return categories.filter(
            ({ path, depth }) =>
                path.startsWith(active.path) && depth === active.depth + 1
        )
    }, [active, categories])

    if (!categories) return null

    return (
        <React.Fragment>
            <ul className="menu">
                <li>
                    {!active && !parent && (
                        <button className="inline" onClick={onCloseCategories}>
                            <Chevron />
                            {t('Back to menu')}
                        </button>
                    )}
                    {active && !parent && (
                        <button
                            className="inline"
                            onClick={() => setActivePath([])}
                        >
                            <Chevron />
                            {t('Back to categories')}
                        </button>
                    )}
                    {active && parent && (
                        <button
                            className="inline"
                            onClick={() =>
                                setActivePath((activePath) => [
                                    ...activePath.slice(0, -1),
                                ])
                            }
                        >
                            <Chevron />
                            {t(`Back to %{category}`, {
                                category: parent.name,
                            })}
                        </button>
                    )}
                </li>
            </ul>
            <div className="menu-holder">
                <h4>{active?.name || t('Categories')}</h4>
                <div className="new-menu">
                    <ul
                        className={classNames(`depth-${active.depth + 1 || 1}`)}
                    >
                        {filteredCategories.map(
                            ({ slug, depth, path, name }) => (
                                <li>
                                    <NavLink
                                        isActive={() => active.slug === slug}
                                        href={`/producten/${slug}`}
                                        onClick={(ev) => {
                                            if (depth === 3) {
                                                if (onClick) onClick()
                                                return
                                            }
                                            ev.preventDefault() // do not follow link
                                            setActivePath((activePath) => [
                                                ...activePath,
                                                { slug, depth, path, name },
                                            ])
                                        }}
                                    >
                                        <span>{name}</span>
                                        {depth < 3 && <Chevron />}
                                    </NavLink>
                                </li>
                            )
                        )}
                        {!!active && (
                            <li className="show-all">
                                <Link href={`/producten/${active.slug}`}>
                                    <a
                                        onClick={() => {
                                            if (onClick) onClick()
                                            return
                                        }}
                                    >
                                        <span>
                                            {t('View all %{items}', {
                                                items: active.name,
                                            })}
                                        </span>
                                    </a>
                                </Link>
                            </li>
                        )}
                        {!active && (
                            <li className="show-all">
                                <Link href="/producten">
                                    <a
                                        onClick={() => {
                                            if (onClick) onClick()
                                            return
                                        }}
                                    >
                                        <span>{t('View all products')}</span>
                                    </a>
                                </Link>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </React.Fragment>
    )
}

MobileCategories.defaultProps = {
    depth: 1,
}

const Categories = ({ onClick }) => {
    const [active, setActive] = useState<string>()
    const isMobile = useIsMobile()
    const { shoppingAreaSlug } = useContext(AreaContext)

    const [categories] = useRequest({
        url: 'categories',
        variables: {
            shoppingAreaSlug,
            layers: 3,
        },
    }) as [Category[]]

    // set the first category active
    useEffect(() => {
        if (active) return
        if (!categories?.[0]?.slug) return
        setActive(categories[0].slug)
    }, [categories, active])

    if (!categories) return null

    const activeCategory =
        active && categories.find((category) => category.slug === active)

    return (
        <div className="new-menu">
            <ul>
                {categories
                    .filter(({ depth }) => depth === 1)
                    .map(({ slug, name, productCount }) => (
                        <li key={slug}>
                            <NavLink
                                isActive={() => active === slug}
                                href={`/producten/${slug}`}
                                onClick={(ev) => {
                                    if (isMobile) {
                                        ev.preventDefault() // do not follow link
                                    }
                                    if (onClick) onClick()
                                }}
                            >
                                <span
                                    onFocus={() => setActive(slug)}
                                    onMouseOver={() => setActive(slug)}
                                >
                                    {name}
                                </span>
                                <Chevron />
                            </NavLink>
                        </li>
                    ))}
            </ul>
            {activeCategory && (
                <SubMenu
                    key={active}
                    onClick={onClick}
                    parent={activeCategory}
                    categories={categories.filter(({ path }) =>
                        path.startsWith(activeCategory.path)
                    )}
                />
            )}
        </div>
    )
}

export default Categories
