
import Trans from '../../../../shared/components/trans'
import ExternalLink from '../../../../shared/router/link-external'

import Logo from '../../../../../svg/logo.svg'

const FooterCopyright = () => (
    <div className="site-footer footer-copyright">
        <div className="inner">
            <div className="copyright">
                <Trans
                    label="zupr.copyright"
                    values={{ current: new Date().getFullYear() }}
                    html
                />
            </div>
            <div className="powered-by">
                <Trans label="Powered by" />
                <ExternalLink href="https://www.zupr.io/">
                    <Logo />
                </ExternalLink>
            </div>
        </div>
    </div>
)

export default FooterCopyright
